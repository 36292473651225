.container {
    padding: var(--padding);
    height: calc(var(--CONTENT-HEIGHT) - 2*var(--padding));
    position: relative;
    margin-top: var(--HEADER-HEIGHT);
    overflow-y: scroll;
    padding-bottom: env(safe-area-inset-bottom);
}

.buttonContainer {
    position: fixed;
    bottom: 0;
    right: 15px;
    margin-bottom: 10px;
    z-index: 5;
}