.container {
    height: 100vh;
    background-color: black;
}

.trans {
    opacity: 0;
    transition: all 200ms;
}

@keyframes enter {
    from {opacity: 0;}
    to {opacity: 1;}
}

.enter {
    opacity: 1;
}

/* .leave {
    opacity: 0;
} */

.popup {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color:rgba(0,0,0,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20000;
    animation: enter 0.3s;
}

.popup_content {
    background-color: white;
    border: 5px solid var(--PRIMARY_GREEN);
    border-radius: 20px;
    width: 80vw;
    height: 200px;
    max-width: 300px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.popup_content p {
    font-size: 20px;
    width: 100%;
    text-align: center;
}
