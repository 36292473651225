.container {
    height: 100vh;
    width: 100vw;
    z-index: 100000;
    position: fixed;
    transition: all 300ms;
}

.drawer {
    /* width: 70%; */
    max-width: 250px;
    height: 100vh;
    background-color: white;
    transition: all 300ms;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.closed {
    visibility: hidden;
    background-color: rgba(0,0,0, 0);
}

.open {
    visibility: visible;
    background-color: rgba(0,0,0, 0.7);
}

.drawerClosed {
    transform: translateX(-100%)
}

.drawerOpen {
    transform: translateX(0)
}

.header {
    height: 100px;
    background-color: var(--PRIMARY_GREEN);
}

.links {
    flex-grow: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.footer {
    height: 100px;
    background-color: pink;
}

.link {
    text-decoration: none;
    font-size: 1.5rem;
}