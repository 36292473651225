.background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.phone {
    background-image: url(../../../assets/wallpapers/phonebakgrund@x2.jpg);
    background-image: image-set(url(../../../assets/wallpapers/phonebakgrund@x1.jpg) 1x,
        url(../../../assets/wallpapers/phonebakgrund@x2.jpg) 2x,
        url(../../../assets/wallpapers/phonebakgrund@x3.jpg) 3x);
    background-image: -webkit-image-set(
        url(../../../assets/wallpapers/phonebakgrund@x1.jpg) 1x,
        url(../../../assets/wallpapers/phonebakgrund@x2.jpg) 2x,
        url(../../../assets/wallpapers/phonebakgrund@x3.jpg) 3x
    );
}

.tablet {
    background-image: url(../../../assets/wallpapers/tabletbakgrund@2x.jpg);
    background-image: image-set(
        url(../../../assets/wallpapers/tabletbakgrund@1x.jpg) 1x,
        url(../../../assets/wallpapers/tabletbakgrund@2x.jpg) 2x,
        url(../../../assets/wallpapers/tabletbakgrund@3x.jpg) 3x
    );
    background-image: -webkit-image-set(
        url(../../../assets/wallpapers/tabletbakgrund@1x.jpg) 1x,
        url(../../../assets/wallpapers/tabletbakgrund@2x.jpg) 2x,
        url(../../../assets/wallpapers/tabletbakgrund@3x.jpg) 3x
    );
    background-size: cover;
}

.web {
    background-image: url(../../../assets/wallpapers/tabletbakgrund@2x.jpg);
}
