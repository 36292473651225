.buttonContainer {
  position: fixed;
  bottom: 0;
  right: 10px;
  margin-bottom: 5px;
}

.wrapperOuter {
  position: relative;
  width: 100%;
  height: 100%;
}

.wrapperInner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%);
  max-height: var(--CARD_MAX_WIDTH);
}

.wrapperInner ul {
  overflow: hidden !important;
}

.wrapperInner li {
  margin: 0 !important;
}

.wrapperInner [id$="overlay"] {
  display: none !important;
}
