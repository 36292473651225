.container {
    height: calc(var(--CONTENT-HEIGHT) + var(--HEADER-HEIGHT));
    position: relative;
}

.list {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    width: 90vw;
    margin: 0 auto;
    padding: 10px 0;
}

.list_container {
    height: var(--CONTENT-HEIGHT);
    margin-top: var(--HEADER-HEIGHT);
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

.shareButtonContainer {
    position: fixed;
    bottom: 10px;
    right: 10px;
}