.container {
    position: fixed;
    height: 100vh;
    width: 100%;
    /* overflow-y: scroll; */
}

/* @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    .container {
      transform: rotate(-90deg);
      transform-origin: left top;
      width: 100vh;
      height: 100vw;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0;
    }
  } */