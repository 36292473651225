@keyframes enterWraper {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.wraper {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  animation: enterWraper 200ms ease-in-out;
  transition: all ease-in-out 200ms;
}

@keyframes enter {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.container {
  border-radius: 10px;
  border: 5px solid var(--PRIMARY_GREEN);
  background-color: white;
  width: 80vw;
  max-width: 401px;
  min-height: 230px;
  padding: 10px;
  position: absolute;
  bottom: 130px;
  left: 30px;
  animation: enter 200ms ease-in-out;
  transition: all ease-in-out 200ms;
  transform-origin: 10% 100%;
  display: flex;
  flex-direction: column;
}

.container p {
  font-size: var(--PRIMARY_P_SIZE);
  flex: 1;
}

.hide {
  transform: scale(0);
}

.hideWraper {
  opacity: 0;
}

.close {
  position: absolute;
  top: -20px;
  left: -20px;
  z-index: 1000;
}

.arrow {
  position: absolute;
  z-index: 0;
  bottom: -26px;
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-top: 26px solid var(--PRIMARY_GREEN);
}

.hider {
  z-index: 0;
  background-color: white;
  height: 15px;
  width: 32px;
  position: absolute;
  bottom: 0px;
  left: 7px;
}

.video {
  z-index: 10;
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  object-fit: cover;
  border-radius: 3px;
}

.video-visible {
  opacity: 1;
}

.videoWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
  opacity: 0;
  transition: opacity 0.5s;
}
.video-wrapper-visible {
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 1;
}

.playbutton_container {
  width: fit-content;
  align-self: flex-end;
  margin: 10px;
}

.playbutton_container_hidden {
  visibility: hidden;
}
