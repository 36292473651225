.nav-left-enter {
    transform: translateX(-100vw);
    position: absolute;
}

.nav-left-enter-active {
    transform: translateX(0);
    transition: var(--TRANSITION_DURATION) var(--TRANSITION_EASE_OUT);
    position: absolute;
}

.nav-left-exit {
    opacity: 1;
    position: absolute;
}

.nav-left-exit-active {
    opacity: 0;
    transform: translateX(100vw);
    transition: var(--TRANSITION_DURATION) var(--TRANSITION_EASE_OUT);
    position: absolute;
}





.nav-right-enter {
    transform: translateX(100vw);
    position: absolute;
}

.nav-right-enter-active {
    transform: translateX(0);
    transition: var(--TRANSITION_DURATION) var(--TRANSITION_EASE_OUT);
    position: absolute;
}

.nav-right-exit {
    opacity: 1;
    position: absolute;
}

.nav-right-exit-active {
    opacity: 0;
    transform: translateX(-100vw);
    transition: var(--TRANSITION_DURATION) var(--TRANSITION_EASE_OUT);
    position: absolute;
}


.nav-up-enter {
    transform: translateY(100vh);
}

.nav-up-enter-active {
    transform: translateY(0);
    transition: var(--TRANSITION_DURATION) var(--TRANSITION_EASE_OUT);
}

.nav-up-exit {
    position: absolute;
    top: 0;
    transform: translateY(0);
}

.nav-up-exit-active {
    transform: translateY(100vh);
    transition: var(--TRANSITION_DURATION) var(--TRANSITION_EASE_OUT);
}




.overview-enter {
    opacity: 0;
}
.overview-enter-active {
    opacity: 1;
    transition: all 300ms;
}

.overview-exit {
    opacity: 1;
}
.overview-exit-active {
    opacity: 0;
    transition: all 300ms;
}


.overviewback-enter {
    opacity: 1;
    transform: translateX(-100vw);
    position: absolute;
}
.overviewback-enter-active {
    opacity: 1;
    transform: translateX(0);
    position: absolute;
    transition: all 250ms;
}

.overviewback-exit {
    opacity: 1;
}
.overviewback-exit-active {
    transform: translateX(100vw);
    transition: var(--TRANSITION_DURATION) var(--TRANSITION_EASE_OUT);
    position: absolute;
}






