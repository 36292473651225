.container {
    --padding: 10px;
    /* padding: var(--padding); */
    /* height: calc(var(--CONTENT-HEIGHT) - 2*var(--padding)); */
    height: var(--CONTENT-HEIGHT);
    position: relative;
    margin-top: var(--HEADER-HEIGHT);
    /* display: flex;
    justify-content: center; */
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.shopButtonContainer {
    position: fixed;
    bottom: 10px;
    left: 15px;
    z-index: 5;
}

.actionButtonContainer {
    position: fixed;
    bottom: 10px;
    right: 15px;
    z-index: 5;
}