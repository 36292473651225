.container {
  background-color: var(--CARD_BACKGROUND_COLOR);
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.3);
  width: 40vw;
  min-width: 300px;
  max-width: var(--CARD_MAX_WIDTH);
  position: relative;
  border: solid 2px #ddd;
  box-sizing: content-box;
}

.imageContainer {
  width: 100%;
  min-width: 300px;
  position: relative;
}

.videoOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.5s;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.5;
}

.video {
  height: 100%;
  width: 100%;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}

.img {
  width: 100%;
  min-width: 300px;
}

.name {
  font-size: 20px;
  font-weight: 100;
  text-align: center;
}

.bottom {
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoButton {
  height: 50px;
  margin: 5px;
  border-radius: 100%;
}

.playButtonContainer {
  height: 60px;
  width: 60px;
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px;
  z-index: 5;
}

.li {
  margin: 10px;
}
