.container {
  background-color: var(--PRIMARY_GREEN);
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.spacer {
  width: 60px;
  height: 30px;
}

.containerActionBtns {
  display: flex;
  align-items: center;
}

.imageButton {
  cursor: pointer;
  max-width: 300px;
}

.btnTitle {
  color: var(--PRIMARY_TEXT_COLOR_LIGHT);
  text-align: center;
  margin-top: 8px;
}

.containerBottom {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 24px;
}
.anchor {
  color: inherit;
}
.logo {
  width: 200px;
}

.containerSmall {
  padding: 20px 0;
  overflow-y: auto;
}
.containerActionBtnsSmall {
  margin-top: 60px;
  flex-direction: column-reverse;
}
.containerBottomSmall {
  flex-direction: column;
  margin-top: 40px;
}
.logoSmall {
  max-width: 120px;
  object-fit: contain;
}

.imageButtonSmall {
  max-width: 200px;
}
.containerBottomLarge {
  position: absolute;
  bottom: 5%;
  left: 5%;
  right: 5%;
}
.containerBottomLarge > h2 {
  margin: 0 24px;
}

.video {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 10;
  background-color: var(--PRIMARY_TEXT_COLOR_DARK);
}
.videoShow {
  display: block;
}

.closeVideoBtn {
  position: absolute;
  z-index: 11;
  top: 20px;
  left: 20px;
}

.imageSmall {
  max-width: 100px;
}

.containerimgBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
}
