.container {
  display: flex;
  border: solid 1px;
  height: 75px;
  width: 100%;
  margin: 10px;
}

.imageWrapper {
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
}

.image {
  width: 75px;
  height: 80%;
  object-fit: cover;
}

.containerPoster {
  position: relative;
  margin-left: 8px;
}

.poster {
  height: 50px;
  width: 50px;
  border-radius: 100%;
}

.video {
  height: 100%;
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
}

.playButtonContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  justify-content: space-around;
}

.textWrapper > h2,
.textWrapper > h3 {
  margin: 0;
  padding: 0;
}
.textWrapper > h3 {
  font-weight: 100;
}

.textWrapper > h2 {
  font-size: 20px;
}

.checked {
  opacity: 0.75;
}
.checked h2 {
  font-style: italic;
}

.checkbox {
  background-color: white;
  height: 100%;
}
