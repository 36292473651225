.container {
  text-align: center;
  position: relative;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.food_player {
  width: 100vw;
  max-width: 100%;
}

.food_player_landscape {
  max-height: 100%;
}

.porttrait {
  background-color: white;
  padding-top: var(--HEADER-HEIGHT);
}

.landscape {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
}

.instruction_text {
  color: white;
  min-height: 50px;
  font-size: 30px;
  margin: 0 0 0px 0;
}

.instruction_text_landscape {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 3px;
  /* width: fit-content; */
  min-width: fit-content;
}

.text_container {
  /* flex-grow: 1; */
  width: 100%;
  /* display: flex; */
  /* justify-content: center; */
  min-width: fit-content;
}

.instruction_text_portrait {
  width: 100vw;
  /* height: 50px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  -webkit-box-shadow: var(--PRIMARY_SHADOW);
  -moz-box-shadow: var(--PRIMARY_SHADOW);
  box-shadow: var(--PRIMARY_SHADOW);
}

.icon_container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.instruction_container_portrait {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 50px;
}

.instruction_container_lanscape {
  width: 100%;
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.instruction_filler {
  width: 100%;
  /* flex-grow: 3;
    background-color: pink;
    height: 2px; */
}

.play-button {
  max-width: 800px;
  width: 100vw;
  height: 60px;
  justify-self: flex-end;
  margin-top: auto;
}

.overlay {
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay_sign {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.initial_play {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.init_play_button {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  /* left: 0;
    right: 0; */
  z-index: 10;
}

.done_container {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--PRIMARY_GREEN);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.done_button_container {
  display: flex;
  justify-content: space-between;
  padding: 0 5%;
  width: 100%;
  position: absolute;
  bottom: 48px;
}

.done_portrait {
  max-width: 80vw;
}
