.container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  opacity: 1;
  visibility: visible;
  transition: 0.5s;
  animation: enter 0.5s;
  z-index: 10;
}

.container_porttrait {
  width: 100%;
  height: 100%;
}

@keyframes enter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.control_containers {
  flex-grow: 1;
}

.one {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 20px;
  position: absolute;
  top: 10px;
}

.two {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 2;
}

.three {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 20px;
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.flex_end {
  justify-content: flex-end;
}

.two_portrait {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: var(--HEADER-HEIGHT);
  width: 100vw;
  opacity: 1;
  transition: 0.5s;
  animation: enter 0.5s;
  visibility: visible;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.three_portrait {
  position: absolute;
  bottom: 10px;
  width: 90vw;
  padding: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.header {
  height: var(--HEADER-HEIGHT);
  width: 100vw;
  background-color: var(--HEADER_COLOR);
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav_button {
  height: 80px;
  width: 80px;
  background-color: var(--PRIMARY_GREEN);
  border-radius: 100px;
}

.player_button {
  height: 100px;
  width: 100px;
  background-color: plum;
  border-radius: 150px;
  margin: 20px;
}

.playbuttons_portrait {
  display: flex;
  justify-content: center;
  align-items: center;
}

.close_button {
  height: 40px;
  width: 40px;
  border: 1px solid white;
  border-radius: 50px;
  margin-left: 5px;
}

.right {
  width: 80px;
}

.status_bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 70%;
  align-items: center;
  min-height: 50px;
}

.status_bar_full_width {
  width: 90%;
}

.step {
  height: 10px;
  width: 10px;
  border-radius: 8px;
}

.done_step {
  background-color: white;
}

.current_step {
  background-color: var(--PRIMARY_GREEN);
}

.remaining_step {
  background-color: gray;
}

.hide {
  opacity: 0;
  visibility: hidden;
}

.icon {
  height: 30px;
  width: 30px;
}

.divider {
  width: 30px;
}
