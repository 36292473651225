.container {
  height: 100vh;
  width: 100vw;
  background-color: var(--PRIMARY_GREEN);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container > h1 {
  text-align: center;
  color: white;
}

.logo {
  width: 500px;
  max-width: 100%;
  object-fit: contain;
}

@keyframes anim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dots {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 5px;
  background-color: var(--PRIMARY_ORANGE);
  border-radius: 10px;
  animation-name: anim;
  animation-iteration-count: infinite;
}
