@font-face {
  font-family: "Calibri";
  font-style: normal;
  font-weight: 400;
  src: url("assets/fonts/Calibri.ttf"); /* IE9 Compat Modes */
}

:root {
  --TRANSITION_EASE_OUT: cubic-bezier(0.17, 0.67, 0.3, 1.01);
  --TRANSITION_DURATION: 250ms;
  --PRIMARY_SHADOW: 0px 3px 5px 0px rgba(0, 0, 0, 0.31);

  /* colors */
  --PRIMARY_GREEN: #52af33;
  --PRIMARY_TEXT_COLOR_DARK: black;
  --PRIMARY_TEXT_COLOR_LIGHT: white;
  --PRIMARY_SHADOW_COLOR: black;
  --PRIMARY_RED: #bc252c;
  --PRIMARY_YELLOW: #fff101;
  --PRIMARY_ORANGE: #ee6e04;
  --PRIMARY_BLUE: #2086bc;
  --PRIMARY_GREY: #b7b7b9;
  --SECONDARY_GREEN: #8cb414;
  --SECONDARY_GREY: #878786;

  /* color assignments */
  --CARD_BACKGROUND_COLOR: white;
  --HEADER_COLOR: var(--PRIMARY_GREEN);

  /* fonts */
  --PRIMARY_FONT_FAMILY: "Calibri";
  --PRIMARY_P_SIZE: 28px;

  /* Sizes */
  --HEADER-HEIGHT: 50px;
  --CONTENT-HEIGHT: calc(100vh - var(--HEADER-HEIGHT));
  --OVERVIEW-IMAGE-WIDTH: 1024px;
  --CARD_MAX_WIDTH: 300px;
}

.safearea_bottom {
  height: env(safe-area-inset-bottom);
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: var(--PRIMARY_FONT_FAMILY);
}

body {
  height: calc(100vh + env(safe-area-inset-bottom));
  background-color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("./assets/wallpapers/tabletbakgrund@2x.jpg");
}

/* Appfärger:
Head Grön, Gå vidare/bakåt pil, Spela film om igen, etc. = 8CB414
Tipps ikon Gul = FFD700
Rosa bakgrundsgrå = B7B7B9
Redskap ”?” knapp Orange = EE6E04
Film Play/Paus knapp Röd = CD1719
Tvätta händerna Blå = 2086BC
Recept steg prickar; Grön = 8cb414, Mörkgrå = 878786

Ikoner Ingredienser Färger:
Bröd, mjöl och socker = (Mörk brun) 7A5D57
Bönor, linser och vegetariska alternativ = (Rosa) FF9999
Fisk och skaldjur = (Mellenblå) 007EB4
Frukt, bär och fruktkonserver = (Orange) FFA14F
Fågel och ägg = (Senapsgul) D9C528
Grönsaker, rotfrukter och grönsakskonserver = (Ljusgrön) A4D647
Kryddor och smaksättning = (Mörkgrön) 779C7B
Kött och korv = (Mörkröd) B0293D
Matfett = (Sandfärgad) EBCE66
Mejerivaror = (Ljusblå) 4AC7E0
Nötter och frön = (Ljusbrun) B57B2F
Potatis, pasta, ris och spannmål = (Beige) E4BC7C
Vätska = (Grå) A3A3A3

Rosa filmer ”Receptsteg” kvadrat (cirkel) 720x720
Recept-, Ingrediens- och Redskapsfilmer: 16:9 = 1280x720 
Rosa bilder/foto”Receptsteg” kvadrat (cirkel) 720x720
Övriga bilder/foto: 16:9 = 1280x720 (Stämmer detta)

Typsnitt/fonter Smartphones:
Rubriktexter = Calibri, Regular, ca 16 pt
Recept namn = Calibri, Regular, ca 11 pt
Recept brödtext intro = mängd: Calibri, Bold, ca 11 pt
Recept brödtext = Calibri, Regular, ca 11 pt
Ingrediens och redskapstexter = Calibri, Regular, ca 10 pt
Recept ”instruktioner” negativa texter = Calibri, Regular, ca 15 pt


Typsnitt/fonter Tablets:
Rubriktexter = Calibri, Regular, ca 26 pt
Recept namn = Calibri, Regular, ca 26 pt
Recept brödtext intro = mängd: Calibri, Bold, ca 14 pt
Recept brödtext = Calibri, Regular, ca 14 pt
Ingrediens och redskapstexter = Calibri, Regular, ca 10 pt
Recept ”instruktioner” negativa texter = Calibri, Regular, ca 22 pt */
