.container {
  --padding: 10px;
  height: var(--CONTENT-HEIGHT);
  position: relative;
  margin-top: var(--HEADER-HEIGHT);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.list {
  margin-bottom: env(safe-area-inset-bottom);
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
