.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.standard {
  background-color: var(--PRIMARY_RED);
  border-radius: 20%;
}

.round {
  background-color: var(--PRIMARY_GREEN);
  border-radius: 100%;
}
