.overview-enter {
  opacity: 0;
}
.overview-enter-active {
  opacity: 1;
  transition: var(--TRANSITION_DURATION) var(--TRANSITION_EASE_OUT);
}

.overview-exit {
  opacity: 1;
}
.overview-exit-active {
  opacity: 0;
  transition: var(--TRANSITION_DURATION) var(--TRANSITION_EASE_OUT);
}

.continue-recipe-enter {
  transform: translateX(100vw);
}

.continue-recipe-enter-active {
  transform: translateX(0);
  transition: all 300ms;
}

.continue-recipe-exit {
  opacity: 1;
}
.continue-recipe-exit-active {
  opacity: 0;
  transition: all 300ms;
}
