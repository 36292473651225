.round {
  border-radius: 100%;
  padding: 10px;
  background-color: var(--PRIMARY_GREEN);
}

.standard {
  padding: 10px;
  border-radius: 10px;
  background-color: blueviolet;
}

.large_round {
  border-radius: 100px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconButton {
  width: 80px;
}

.green {
  background-color: var(--PRIMARY_GREEN);
}

.yellow {
  background-color: var(--PRIMARY_YELLOW);
}

.blue {
  background-color: var(--PRIMARY_BLUE);
}

.orange {
  background-color: var(--PRIMARY_ORANGE);
}

.elevation {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.play {
  height: 60px;
  width: 60px;
}

.playRosa {
  background-color: var(--PRIMARY_RED);
  border-radius: 20%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: 35%;
}

.rosa {
  max-width: 100%;
  max-height: 100%;
  border-radius: 100%;
}

.notype {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--PRIMARY_GREY);
  margin: 5px;
  min-width: 70px;
  text-align: center;
}

.image {
  height: 75px;
  width: 75px;
}

@media screen and (max-width: 470px) {
  .done {
    width: 80%;
  }
}
