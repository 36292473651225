@keyframes descriptionEnter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes titleEnter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes buttonsEnter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10000;
}

.img {
  width: 100%;
  max-width: 1024px;
}

.description_container {
  animation: descriptionEnter var(--TRANSITION_DURATION)
    var(--TRANSITION_EASE_OUT);
  background-color: white;
  width: 100vw;
}

.description_container_tablet {
  text-align: center;
  display: flex;
  justify-content: center;
}

.description_container p {
  padding: 10px 30px 10px 30px;
  font-size: var(--PRIMARY_P_SIZE);
}

.description_container_tablet p {
  max-width: 55%;
}

.title_container {
  animation: titleEnter var(--TRANSITION_DURATION) var(--TRANSITION_EASE_OUT);
  background-color: var(--PRIMARY_GREEN);
  width: 100vw;
  text-align: center;
  padding: 10px;
}

.title_container h1 {
  color: white;
  font-size: 27px;
}
.playButton {
  height: 100px;
  width: 100px;
  opacity: 1;
  transition: all 1000ms;
}

.playButton.hidden {
  opacity: 0;
}

.playButtonTablet {
  position: absolute;
  top: 20px;
  right: 20px;
}

.videoContainer {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  transition: all 1000ms;
}

.video_container_tablet {
  justify-content: center;
  object-fit: cover;
  padding-top: 16px;
}

.video {
  max-width: 100%;
  transition: all 1000ms;
}

.videoTablet {
  width: 45vw;
  height: 45vw;
  border-radius: 100%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  object-fit: cover;
}

.videoContainer.videoShow {
  opacity: 1;
  visibility: visible;
}

.videoContainer.videoHidden {
  opacity: 0;
  visibility: hidden;
}

.video_filler {
  width: 100%;
  background-color: rgb(190, 189, 194);
}

.close {
  position: absolute;
  top: 10px;
  left: 10px;
}

.closeIcon {
  height: 30px;
  width: 30px;
}

.buttonContainer {
  animation: buttonsEnter var(--TRANSITION_DURATION) var(--TRANSITION_EASE_OUT);
  width: 100vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  margin-bottom: 20px;
  bottom: 0;
}

.buttonContainerTablet {
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.blur {
  -webkit-backdrop-filter: blur(20px);
}

.actionButton {
  width: 65px;
  height: 65px;
  flex: 1;
}

.tipsContainer {
  display: flex;
}
