.container {
    display: flex;
    align-items: center;
    width: fit-content;
    padding-right: 5px;
}

.container p {
    font-size: 25px;
}

.icon_container {
    height: 40px;
    width: 40px;
    margin: 5px;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.3);
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.container img {
    height: 40px;
}

.container p {
    color: white;
}

.yellow {
    background-color: darkorange;
}

.green {
    background-color: darkolivegreen;
}